import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, chakra, Flex, Link, Spinner, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ExternalLinkBoldIcon } from 'src/components/common/Icons/ExternalLinkBoldIcon'
import { useUltraTopicsCount } from 'src/hooks/ultra/topics/useUltraTopicsCount'
import { useAccounts } from 'src/hooks/useAccounts'
import { useATLink } from 'src/hooks/useATLink'
import { WithUnclickableTooltip } from 'src/pages/demo/components/UnclickableTooltip'
import { useDemoContext } from 'src/pages/demo/contexts/DemoContext'

export type NavItemType = {
  linkToExternalPage?: string | null
  href: string | null
  label: string
  icon?: JSX.Element
  fontSize?: string
  isUltraOnly: boolean
  isViewRealEstate: boolean
}

const ProvokeConnectingNavItem: React.FC<{
  isLoaging: boolean
  onClick?: VoidFunction
}> = ({ onClick, isLoaging }) => {
  return (
    <Box
      onClick={onClick}
      paddingY="1em"
      pr="24px"
      pl="24px"
      bg="purple.100"
      mt="2px"
      color="purple.900"
    >
      <Flex>
        <Text fontSize="13px" fontWeight="900" lineHeight="1">
          まずは口座・クレカ連携
        </Text>
      </Flex>
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="6px"
        bg="primary.purple"
        color="white.primary"
        px="8px"
        py="1px"
        mt="8px"
      >
        {isLoaging && <Spinner mr="4px" w="13px" h="13px" />}
        <Text fontSize="13px">外部サイト</Text>
        <ExternalLinkBoldIcon
          height={'16px'}
          width={'16px'}
          ml={'8px'}
          stroke="white.primary"
        />
      </Box>
    </Box>
  )
}
export const NavLink = (props: Omit<NavItemType, 'isUltraOnly'>) => {
  const { asPath } = useRouter()
  const isCurrentPage = props.href === asPath
  const { isLoading, redirectToAT } = useATLink()

  const AccountConnectLink: React.FC<{ onClick?: VoidFunction }> = ({
    onClick
  }) => {
    const { accounts } = useAccounts()
    const connected_accounts = accounts?.items?.filter((account) => {
      return account.account_id > 0
    })

    return (
      <>
        {!connected_accounts || connected_accounts.length === 0 ? (
          <ProvokeConnectingNavItem isLoaging={isLoading} onClick={onClick} />
        ) : (
          <NavItem onClick={onClick} />
        )}
      </>
    )
  }

  const InternalLinkWrapper: React.VFC = () => (
    <NextLink
      {...(props.href
        ? { href: props.href, passHref: true }
        : { href: '', passHref: false })}
    >
      <Link>
        <NavItem />
      </Link>
    </NextLink>
  )

  const ExternalLinkWrapper: React.VFC = () => (
    <Link
      {...(props.linkToExternalPage
        ? {
            href: props.linkToExternalPage,
            target: '_blank',
            rel: 'noreferrer'
          }
        : { href: '' })}
    >
      <NavItem />
    </Link>
  )

  const TopicsCount: React.FC = () => {
    const { count } = useUltraTopicsCount()

    return count === 0 || count === undefined ? null : (
      <chakra.span
        w="20px"
        h="20px"
        fontSize={count > 9 ? '10px' : '12px'}
        fontWeight={700}
        lineHeight="12px"
        py="4px"
        px={count > 9 ? '4px' : '6px'}
        borderRadius="full"
        color="white"
        bgColor={isCurrentPage ? 'primary.black' : 'gray.700'}
      >
        {count}
      </chakra.span>
    )
  }

  const NavItem: React.FC<{ onClick?: VoidFunction }> = ({ onClick }) => {
    const { isDemo } = useDemoContext()

    return WithUnclickableTooltip(
      isDemo,
      <Flex
        onClick={onClick}
        boxSizing="border-box"
        height={'60px'}
        py="20px"
        pr="24px"
        pl={isCurrentPage ? '20px' : '24px'}
        alignItems="center"
        borderLeft={isCurrentPage ? '4px solid currentColor' : 'none'}
        bg={isCurrentPage ? 'gray.EFE' : 'transparent'}
        _hover={{ bg: 'gray.EFE' }}
        color={isCurrentPage ? 'black.primary' : 'gray.700'}
        cursor={isDemo ? 'not-allowed' : 'pointer'}
      >
        {isLoading ? <Spinner w="16px" h="16px" /> : props.icon}
        <Text
          marginLeft={props.icon && '1em'}
          fontSize={props.fontSize}
          fontWeight={isCurrentPage ? 'bold' : '500'}
          {...(props.label === '仕訳の質問' && {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1
          })}
        >
          {props.label}
          {
            //「仕訳の質問」のみ外部リンクアイコンを設置
            props.label === '仕訳の質問' && <TopicsCount />
          }
        </Text>
        {
          //「口座・クレカ連携」のみ外部リンクアイコンを設置
          props.linkToExternalPage === '/at/register' && (
            <ExternalLinkIcon height={'16px'} width={'16px'} ml={'8px'} />
          )
        }
      </Flex>
    )
  }

  // ATとの連携ページはサーバーでトークン付きのURLを返してもらい、
  // そこにリダイレクトする必要があるのでNext.js内の遷移と分ける
  return props.href === '/at/register' ? (
    <Link>
      <AccountConnectLink onClick={() => redirectToAT()} />
    </Link>
  ) : props.linkToExternalPage ? (
    <ExternalLinkWrapper />
  ) : (
    <InternalLinkWrapper />
  )
}
