import { Box, Divider, Image, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import { response_user_userType } from 'src/api'
import { ChatIcon } from 'src/components/common/Icons/ChatIcon'
import { DocumentIcon } from 'src/components/common/Icons/DocumentIcon'
import { SidemenuCategoryIcon } from 'src/components/common/Icons/SidemenuCategoryIcon'
import GlobalContext from 'src/context/GlobalContext'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useUserInfo } from 'src/hooks/user/useUserInfo'
import { useUserType } from 'src/hooks/useUserType'
import { useIsAdminLogin } from 'src/hooks/zo_admin/useIsAdminLogin'
import { useDemoContext } from 'src/pages/demo/contexts/DemoContext'
import {
  INVOICES_PATH,
  JOURNALS_PATH,
  PAGE_PATH,
  REPORT_PATH,
  ROOT_PATH,
  REAL_ESTATE_PATH
} from 'src/routes'
import { InvoicePageIcon } from '../../common/Icons/InvoicePageIcon'
import { JournalListIcon } from '../../common/Icons/JournalListIcon'
import { ReportMenuIcon } from '../../common/Icons/ReportMenuIcon'
import { TaskListIcon } from '../../common/Icons/TaskListIcon'
import Layout from '../Layout'
import { ActiveSubscriptionAlert } from './ActiveSubscriptionAlert'
import { DetailSettingsMenu } from './DetailSettingsMenu'
import { NavItemType, NavLink } from './NavLink'
import { UserSettingsMenu } from './UserSettingsMenu'

const NavItemsSub: NavItemType[] = [
  {
    linkToExternalPage: '/at/register',
    label: '口座・クレカ連携',
    href: '/at/register',
    isUltraOnly: false,
    isViewRealEstate: false
  }
]

const { MARUNAGE, SEMI_MARUNAGE, REAL_ESTATE } =
  response_user_userType.user_type

const Sidemenu: React.FC = ({ children }) => {
  const { isDemo } = useDemoContext()
  const context = useContext(GlobalContext)
  const router = useRouter()
  const { isLogin } = context
  const { userInfo } = useUserInfo()
  const { userType } = useUserType()
  const { isAdminLogin } = useIsAdminLogin()
  const { processing_year } = useProcessingYear()

  const getHref = (userType?: response_user_userType.user_type): string => {
    if (userType === MARUNAGE || userType === SEMI_MARUNAGE) {
      return PAGE_PATH.ultra.index
    } else if (userType === REAL_ESTATE) {
      return REAL_ESTATE_PATH
    }
    return ROOT_PATH
  }

  const NavItems: NavItemType[] = [
    {
      label: '確定申告',
      href: getHref(userType),
      icon: <TaskListIcon stroke={'currentcolor'} />,
      isUltraOnly: false,
      isViewRealEstate: true
    },
    {
      label: '仕訳の質問',
      href: PAGE_PATH.ultra.chat.index,
      icon: <ChatIcon />,
      isUltraOnly: true,
      isViewRealEstate: false
    },
    ...(isAdminLogin && processing_year
      ? [
          {
            label: 'カテゴリ登録',
            href: PAGE_PATH.transactions_by_year(
              processing_year.processing_year
            ),
            icon: <SidemenuCategoryIcon />,
            isUltraOnly: false,
            isViewRealEstate: false
          }
        ]
      : []),
    {
      label: '収入・経費の一覧',
      href: JOURNALS_PATH,
      icon: <JournalListIcon stroke={'currentcolor'} />,
      isUltraOnly: false,
      isViewRealEstate: false
    },
    {
      label: '請求書',
      href: INVOICES_PATH,
      icon: <InvoicePageIcon stroke={'currentcolor'} fill={'currentcolor'} />,
      isUltraOnly: false,
      isViewRealEstate: false
    },
    {
      label: '納税予測',
      href: REPORT_PATH,
      icon: <ReportMenuIcon stroke={'currentcolor'} fill={'currentcolor'} />,
      isUltraOnly: false,
      isViewRealEstate: true
    },
    {
      label: '開業届',
      href: PAGE_PATH.tax_tasks.opening.index,
      icon: <DocumentIcon stroke={'currentcolor'} fill={'currentcolor'} />,
      isUltraOnly: false,
      isViewRealEstate: false
    }
  ]

  return (
    <Box h="100%">
      {/* いつかドロワーにする気がするのでFIXED MENU + マージン運用にします */}
      <Box
        // SP対応
        display={['none', 'block']}
        bg="gray.F8F"
        pos="fixed"
        h="full"
        w="200px"
        borderRight={'1px solid'}
        borderRightColor={'gray.F0F'}
        zIndex="2"
      >
        <Box paddingTop={'8px'}>
          <Box
            onClick={() => {
              if (isDemo) return

              if (userType === MARUNAGE || userType === SEMI_MARUNAGE) {
                router.push(PAGE_PATH.ultra.index)
              } else {
                router.push(ROOT_PATH)
              }
            }}
            alignItems="center"
            paddingX="1.5em"
            paddingY={'0.7em'}
            cursor={isDemo ? 'not-allowed' : 'pointer'}
            gap="10px"
          >
            <Image src="/images/logo.svg" w="128px" />
            <Text
              fontFamily={'Noto Sans JP'}
              mt="8px"
              fontSize="14px"
              fontWeight="500"
              color="gray.700"
            >
              （旧ZeiOne）
            </Text>
            {isDemo && (
              <Box
                fontSize="14px"
                lineHeight={1}
                fontWeight={700}
                bgColor="primary.purple"
                borderRadius="2px"
                py="6px"
                px="8px"
                color="white.100"
                h="26px"
              >
                デモ
              </Box>
            )}
          </Box>
        </Box>

        {/* メインメニュー */}
        <Box paddingY={'2px'}>
          {NavItems.filter((item) => {
            if (userType === MARUNAGE || userType === SEMI_MARUNAGE) {
              return true
            } else if (userType === REAL_ESTATE) {
              return item.isViewRealEstate
            }
            return !item.isUltraOnly
          }).map((link, key) => {
            return (
              <NavLink
                {...link}
                href={isDemo ? null : link.href}
                linkToExternalPage={isDemo ? null : link.linkToExternalPage}
                key={key}
                fontSize={'14px'}
              />
            )
          })}
        </Box>

        <Divider color={'gray.F0F'} />

        {/* サブメニュー */}
        <Box paddingY={'0px'}>
          {NavItemsSub.filter((item, i) => {
            if (userType === REAL_ESTATE) {
              return false
            }
            if (isDemo) {
              return i === 0
            }
            return true
          }).map((link, key) => {
            return (
              <NavLink
                {...link}
                href={isDemo ? null : link.href}
                linkToExternalPage={isDemo ? null : link.linkToExternalPage}
                key={key}
                fontSize={'12px'}
              />
            )
          })}
        </Box>
        <DetailSettingsMenu />

        {isLogin() && !isDemo && (
          <>
            <Divider color="#DFE0EB" />
            <UserSettingsMenu />
          </>
        )}
        {/* <NavLink
          label="お友達招待"
          href={isDemo ? null : INVITATION_PATH}
          fontSize={'12px'}
        /> */}
        {isLogin() && !isDemo && (
          <>
            <Divider color="#DFE0EB" />
            <Text
              color="gray.600"
              fontSize="12px"
              paddingY="1em"
              pr="24px"
              pl="24px"
            >
              {userInfo?.email}
            </Text>
          </>
        )}
        <ActiveSubscriptionAlert />
      </Box>

      {/* いつかドロワーにする気がするのでFIXED MENU + マージン運用にします */}
      <Box ml={[0, '200px']} overflowX="scroll" overflowY={'scroll'} h="100%">
        {children}
      </Box>
    </Box>
  )
}

const LayoutWithSidebar: React.FC = ({ children }) => {
  return (
    <Layout>
      <Sidemenu>{children}</Sidemenu>
    </Layout>
  )
}
export default LayoutWithSidebar
